<template>
  <div>
    <v-snackbar v-model="snackbar" :timeout="4000" top color="success">
      <span>Opération réussie.</span>
      <v-btn text color="white" @click="snackbar = false">Fermer</v-btn>
    </v-snackbar>
    <v-dialog v-model="dialog">
      <template v-slot:activator="{ on }">
        <v-btn fab bottom right color="primary" fixed x-large v-on="on">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-alert v-if="error" type="error">
                <ul class="alert alert-danger">
                  <li v-for="(errorD, index) in error.details" :key="index">{{ errorD[0] }}</li>
                </ul>
              </v-alert>
              <v-col cols="12" sm="12" md="12">
                <v-row>
                  <v-col cols="12" sm="2" md="2">
                    <v-select v-model="editedItem.gender" :items="genders" label="Genre*" item-text="name" item-value="key" :rules="nameRules"></v-select>
                  </v-col>
                  <v-col cols="12" sm="5" md="5">
                    <v-text-field v-model="editedItem.last_name" label="Nom*" hide-details="auto" :rules="nameRules"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="5" md="5">
                    <v-text-field v-model="editedItem.first_name" label="Prénom*" hide-details="auto" :rules="nameRules"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="editedItem.birth_date" label="Date de naissance*" hide-details="auto" :rules="[rules.dateFormat]"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea v-model="editedItem.health_situation" label="Etat de santé" rows="1" auto-grow ></v-textarea>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea v-model="editedItem.comments" label="Remarques" rows="1" auto-grow ></v-textarea>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn color="blue darken-1" text @click="close">Annuler</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="save">Enregistrer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card>
      <v-card-title>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Recherche" single-line hide-details></v-text-field>
      </v-card-title>
      <v-data-table must-sort :headers="headers" :search="search" :items="students" :options.sync="options" :server-items-length="totalStudents" :loading="loading" class="elevation-1">
        <template v-slot:item.gender="{ item }">
          <v-avatar v-if="item.gender === 'male'">Garçon</v-avatar>
          <v-avatar v-else-if="item.gender === 'female'">Fille</v-avatar>
          <v-avatar v-else color="teal">
            <span class="white--text">CJ</span>
          </v-avatar>
        </template>
        <template v-slot:item.birth_date="{ item }">
           {{formatedDate(item.birth_date)}}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon @click="deleteItem(item)">mdi-delete</v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>

import { APIServices } from '@/api/api';
export default {
  data: () => ({
    pagination: {
      descending: true,
      page: 1,
      rowsPerPage: 4,
      sortBy: "last_name",
      totalItems: 0,
      rowsPerPageItems: [1, 2, 4, 8, 16]
    },
    genders: [
      {
        name: "Garçon",
        key: "male"
      },
      {
        name: "Fille",
        key: "female"
      }
    ],
    dialog: false,
    file: null,
    search: "",
    nameRules: [v => !!v || "Champs obligatoire"],
    rules: {
        dateFormat: value => {    
          const pattern = /^(0?[1-9]|[12][0-9]|3[01])[/-](0?[1-9]|1[012])[/-]\d{4}$/;
          return pattern.test(value) || "Date invalide.";
        }
      }, 
    headers0: [
      { text: "Gender", value: "gender", sortable: false },
      { text: "Lastname", value: "last_name" },
      { text: "Firstname", value: "first_name" },
      { text: "Dateofbirth", value: "birth_date" },
      { text: "Health situation", value: "health_situation" },
      { text: "Comments", value: "comments" },
      { text: "Actions", value: "actions", sortable: false }
    ],
    students: [],
    editedIndex: -1,
    editedItem: {
      gender: "",
      lastname: null,
      firstname: "",
      dateofbirth: "",
      health_situation: "",
      comments: ""
    },
    defaultItem: {
      gender: "",
      lastname: null,
      firstname: "",
      dateofbirth: "",
      health_situation: "",
      comments: ""
    },
    teacher: {
      name: ""
    },
    error: null,
    totalStudents: 0,
    options: {
      page: 1,
      itemsPerPage: 10,
      sortBy: ["last_name"],
      sortDesc: [true]
    },
    loading: false,
    snackbar: false
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Ajout d'un élève" : "Modification d'un élève";
    },
     headers() {
      return [
        { text: this.$t("gender"), value: "gender", sortable: false },
        { text: this.$t("lastname"), value: "last_name" },
        { text: this.$t("firstname"), value: "first_name" },
        { text: this.$t("birthdate"), value: "birth_date" },
        { text: this.$t("health_situation"), value: "health_situation" },
        { text: this.$t("comments"), value: "comments" },
        { text: "Actions", value: "actions", sortable: false },
      ];
    }
  },

  watch: {
    dialog(val) {
      console.log("watch dialog :" + this.dialog);
      val || this.close();
    },
    options: {
      handler() {
        this.getDataFromApi().then(data => {
          this.students = data.items;
          this.totalStudents = data.total;
        });
      },
      deep: true
    },
    search(val) {
      console.log(val);
      this.getDataFromApi().then(data => {
        this.students = data.items;
        this.totalStudents = data.total;
      });
    }
  },
  mounted() {
    this.getDataFromApi().then(data => {
      this.students = data.items;
      this.totalStudents = data.total;
    });
  },

  methods: {
    formatedDate(date) {
      return this.$moment(date).format("Do MMMM YYYY");
    },
    loadingTutors() {
      this.getDataFromApi().then(data => {
        this.students = data.items;
        this.totalStudents = data.total;
      });
    },
    getDataFromApi() {
      this.loading = true;
      return new Promise(resolve => {
        /* let search = this.search.trim().toLowerCase();*/

        let items = this.getJsonData().then(response => {
          items = response.data;

          /* if (this.search) {
            items = items.filter(item => {
              return Object.values(item)
                .join(",")
                .toLowerCase()
                .includes(this.search);
            });
          }*/

          /* if (this.options.sortBy) {
            items = items.sort((a, b) => {
              const sortA = a[sortBy];
              const sortB = b[sortBy];

              if (descending) {
                if (sortA < sortB) return 1;
                if (sortA > sortB) return -1;
                return 0;
              } else {
                if (sortA < sortB) return -1;
                if (sortA > sortB) return 1;
                return 0;
              }
            });
          }*/

          /*if (rowsPerPage > 0) {
            items = items.slice((page - 1) * rowsPerPage, page * rowsPerPage);
          }*/

          const total = response.total;

          setTimeout(() => {
            this.loading = false;
            resolve({
              items,
              total
            });
          }, 300);
        });
      });
    },

    getJsonData() {
      //  Aqui pra ficar completo falta o parametro de filtro que a
      // a API so SWAPI não me da...
      var sortDesc = null;
      if (this.options.sortDesc[0]) {
        sortDesc = "asc";
      } else {
        sortDesc = "desc";
      }
      console.log(`/students?page=${this.options.page}&per_page=${this.options.itemsPerPage}&sort_by=${this.options.sortBy}&sort_desc=${sortDesc}&search=${this.search}`);
      return APIServices
        .get(`/students?page=${this.options.page}&per_page=${this.options.itemsPerPage}&sort_by=${this.options.sortBy}&sort_desc=${sortDesc}&search=${this.search}`)
        .then(function(response) {
          var result = response.data;
          //console.log(result);
          return result;
        })
        .catch(function(error) {
          // handle error
          console.log(error);
        });
    },

    updatePagination(pagination) {
      console.log("update:pagination", pagination);
    },
    loadData(file) {
      let self = this;
      if (file != undefined) {
        var reader = new FileReader();
        reader.onload = function(data) {
          data = reader.result;
          var xmlDoc;
          var parser;
          parser = new DOMParser();
          xmlDoc = parser.parseFromString(data, "text/xml");
          self.editedItem.gender = xmlDoc.getElementsByTagName("identity")[0].attributes["gender"].nodeValue;
          let dateofbirth = xmlDoc.getElementsByTagName("identity")[0].attributes["dateofbirth"].nodeValue;
          self.editedItem.birth_date = self.$moment(dateofbirth, "YYYYMMDD").format("YYYY-MM-DD");
          self.editedItem.last_name = xmlDoc.getElementsByTagName("name")[0].childNodes[0].nodeValue;
          self.editedItem.first_name = xmlDoc.getElementsByTagName("firstname")[0].childNodes[0].nodeValue;
          self.editedItem.address = xmlDoc.getElementsByTagName("streetandnumber")[0].childNodes[0].nodeValue;
          self.editedItem.zip = xmlDoc.getElementsByTagName("zip")[0].childNodes[0].nodeValue;
          self.editedItem.city = xmlDoc.getElementsByTagName("municipality")[0].childNodes[0].nodeValue;
          //self.editedItem.photo = "data:image/jpg;base64," + xmlDoc.getElementsByTagName("photo")[0].childNodes[0].nodeValue;
        };
        reader.readAsText(file, "UTF-8");
      }
    },
    initialize() {
      this.loading = true;
      APIServices
        .get("/students")
        .then(response => {
          console.log(response);
          this.students = response.data.data;
          this.loading = false;
        })
        .catch(e => {
          this.error = e.response.data;
          console.log(e.response.data);
          this.loading = false;
        });
    },

    editItem(item) {
      this.editedIndex = this.students.indexOf(item);
      this.editedItem = Object.assign({}, item);

      //formater date
      this.editedItem.birth_date = this.$moment(this.editedItem.birth_date, "YYYY-MM-DD").format("DD/MM/YYYY");

      this.dialog = true;
    },

    deleteItem(item) {
      confirm("Êtes-vous sûr de bien vouloir supprimer cet élève ?") && this.deleteTutor(item);
    },
    deleteTutor(item) {
      this.loading = true;
      APIServices
        .delete("/students/" + item.id)
        .then(response => {
          console.log(response);
          this.snackbar = true;
          this.loadingTutors();
          this.loading = false;
        })
        .catch(e => {
          this.error = e.response.data;
          console.log(e.response.data);
          this.loading = false;
        });
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
      this.file = null;
      this.error = null;
    },

    clear() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
      this.file = null;
    },

    save() {
      this.loading = true;
      console.log(this.editedIndex);
      if (this.editedIndex > -1) {
        console.log("Mode edition");
        console.log(`/students/` + this.editedItem.id);

        // formatage birth_date
        this.editedItem.birth_date = this.$moment(this.editedItem.birth_date, "DD/MM/YYYY").format("YYYY-MM-DD");


        APIServices
          .put(`/students/` + this.editedItem.id, this.editedItem)
          .then(response => {
            this.snackbar = true;
            console.log(response.data);
            this.tutor = response.data;
            Object.assign(this.students[this.editedIndex], this.editedItem);
            this.loading = false;
            this.close();
          })
          .catch(e => {
            this.error = e.response.data;
            console.log(e.response.data);
            this.loading = false;
          });
      } else {
        console.log("Mode ajout");

        // formatage birth_date
        this.editedItem.birth_date = this.$moment(this.editedItem.birth_date, "DD/MM/YYYY").format("YYYY-MM-DD");

        APIServices
          .post(`/students`, this.editedItem)
          .then(response => {
            this.snackbar = true;
            console.log(response);
            this.tutor = response.data;
            this.loadingTutors();
            this.loading = false;
            this.close();
          })
          .catch(e => {
            this.error = e.response.data;
            console.log(e.response.data);
            this.loading = false;
          });
      }
    }
  }
};
</script>

<style></style>
